import { service, serviceBuild } from "../_helpers";

export const contactPersonService = {
  addContactPerson,
  updatePersonOfInterestContactPerson,
  getContactPersonByPersonOfInterestID,
  deletePersonOfInterestContactPerson,
};


function addContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/addPersonOfInterestContactPerson`, contactPerson);
}

function updatePersonOfInterestContactPerson(contactPerson) {
  return service.fetch.post(`/api/contactperson/updatePersonOfInterestContactPerson`, contactPerson);
}


function getContactPersonByPersonOfInterestID(personOfInterestID) {
  return service.fetch.get(serviceBuild.buildUrl(`/api/contactperson/getContactPersonByPersonOfInterestID`, { personOfInterestID }));
}

function deletePersonOfInterestContactPerson(contactPersonID) {
  return service.fetch.delete(serviceBuild.buildUrl(`/api/contactperson/deletePersonOfInterestContactPerson`, { contactPersonID }));
}