import { assetConstants } from "../_constants";
import { assetService } from "../_services";

export const assetActions = {
  getList,
  getByMatterID,
  getUsufructBeneficiaries,
  getDropDownListByMatterID,
  getCGTByMatterID,
  getByAssetID,
  getByAuditAssetID,
  getReconAssetByID,
  add,
  update,
  _deleteReconAsset,
  _delete,
  sendValuerEmail,
  sendAuctioneerEmail,
  sendBuyerEmail,
  addUsufructBeneficiary,
  addSignedOTPAudit,
  addActionDateAudit,
  addValuationAudit,
  addSaleResultAudit,
  saveAssetReconAmount,
  getAwardListByAssetID,
  getReconItemsByAssetID,
  updateAwardBeneficiary,
  updateUsufructBeneficiary,
  _deleteAssetBeneficiary,
  deleteUsufructBeneficiary,
  updateReconAssetAmount,
};
function updateReconAssetAmount(assets) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      .updateReconAssetAmount(assets)
      .then((assets) => dispatch(success(assets)));
  };

  function request() {
    return {
      type: assetConstants.UPDATE_REQUEST,
    };
  }
  function success(assets) {
    return {
      type: assetConstants.UPDATE_SUCCESS,
      assets,
    };
  }
  function failure(error) {
    return {
      type: assetConstants.UPDATE_FAILURE,
      error,
    };
  }
}
function getList(skip, take, searchString) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      .getList(skip, take, searchString)
      .then((assets) => dispatch(success(assets)));
  };

  function request() {
    return { type: assetConstants.GETLIST_REQUEST };
  }
  function success(assets) {
    return { type: assetConstants.GETLIST_SUCCESS, assets };
  }
  function failure(error) {
    return { type: assetConstants.GETLIST_FAILURE, error };
  }
}
function getReconItemsByAssetID(assetID) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      .getReconItemsByAssetID(assetID)
      .then((reconAssets) => dispatch(success(reconAssets)));
  };

  function request() {
    return { type: assetConstants.GET_ASSETRECON_BY_ASSETID_REQUEST };
  }
  function success(reconAssets) {
    return {
      type: assetConstants.GET_ASSETRECON_BY_ASSETID_SUCCESS,
      reconAssets,
    };
  }
  function failure(error) {
    return { type: assetConstants.GET_ASSETRECON_BY_ASSETID_FAILURE, error };
  }
}
function getAwardListByAssetID(id, skip, take) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      .getAwardListByAssetID(id, skip, take)
      .then((awards) => dispatch(success(awards)));
  };

  function request() {
    return { type: assetConstants.GETAWARDLISTBYASSETID_REQUEST };
  }
  function success(awards) {
    return { type: assetConstants.GETAWARDLISTBYASSETID_SUCCESS, awards };
  }
  function failure(error) {
    return { type: assetConstants.GETAWARDLISTBYASSETID_FAILURE, error };
  }
}

function getCGTByMatterID(matterId, searchString, cgtType) {
  return (dispatch) => {
    dispatch(request());
    return assetService
      .getCGTByMatterID(matterId, searchString, cgtType)
      .then((assets) => dispatch(success(assets)));
  };

  function request() {
    return { type: assetConstants.GETCGTBYMATTERID_REQUEST };
  }
  function success(assets) {
    return { type: assetConstants.GETCGTBYMATTERID_SUCCESS, assets };
  }
  function failure(error) {
    return { type: assetConstants.GETCGTBYMATTERID_FAILURE, error };
  }
}

function getDropDownListByMatterID(matterId) {
  return (dispatch) => {
    dispatch(request());
    return assetService
      .getDropDownListByMatterID(matterId)
      .then((assets) => dispatch(success(assets)));
  };

  function request() {
    return { type: assetConstants.GETDROPDOWNLISTBYMATTERID_REQUEST };
  }
  function success(assets) {
    return { type: assetConstants.GETDROPDOWNLISTBYMATTERID_SUCCESS, assets };
  }
  function failure(error) {
    return { type: assetConstants.GETDROPDOWNLISTBYMATTERID_FAILURE, error };
  }
}

function getUsufructBeneficiaries(assetID) {
  return (dispatch) => {
    dispatch(request());
    return assetService
      .getUsufructBeneficiaries(assetID)
      .then((beneficiaries) => dispatch(success(beneficiaries)));
  };

  function request() {
    return { type: assetConstants.GETUSURFRUCTBENEFICIARIES_REQUEST };
  }
  function success(beneficiaries) {
    return {
      type: assetConstants.GETUSURFRUCTBENEFICIARIES_SUCCESS,
      beneficiaries,
    };
  }
  function failure(error) {
    return { type: assetConstants.GETUSURFRUCTBENEFICIARIES_FAILURE, error };
  }
}

function getByMatterID(skip, take, searchString, matterId) {
  return (dispatch) => {
    dispatch(request());
    return assetService
      .getByMatterID(skip, take, searchString, matterId)
      .then((assets) => dispatch(success(assets)));
  };

  function request() {
    return { type: assetConstants.GETBYMATTERID_REQUEST };
  }
  function success(assets) {
    return { type: assetConstants.GETBYMATTERID_SUCCESS, assets };
  }
  function failure(error) {
    return { type: assetConstants.GETBYMATTERID_FAILURE, error };
  }
}
function getReconAssetByID(id) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      .getReconAssetByID(id)
      .then((reconAssets) => dispatch(success(reconAssets)));
  };

  function request() {
    return { type: assetConstants.GET_ASSETRECON_BY_ID_REQUEST };
  }
  function success(reconAssets) {
    return { type: assetConstants.GET_ASSETRECON_BY_ID_SUCCESS, reconAssets };
  }
  function failure(error) {
    return { type: assetConstants.GET_ASSETRECON_BY_ID_FAILURE, error };
  }
}
function getByAssetID(id) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      .getByAssetID(id)
      .then((asset) => dispatch(success(asset)));
  };

  function request() {
    return { type: assetConstants.GETBYASSETID_REQUEST };
  }
  function success(asset) {
    return { type: assetConstants.GETBYASSETID_SUCCESS, asset };
  }
  function failure(error) {
    return { type: assetConstants.GETBYASSETID_FAILURE, error };
  }
}

function getByAuditAssetID(id, skip, take) {
  return (dispatch) => {
    dispatch(request());
    return assetService
      .getByAuditAssetID(id, skip, take)
      .then((assets) => dispatch(success(assets)));
  };

  function request() {
    return { type: assetConstants.GETBYAUDITASSETID_REQUEST };
  }
  function success(assets) {
    return { type: assetConstants.GETBYAUDITASSETID_SUCCESS, assets };
  }
  function failure(error) {
    return { type: assetConstants.GETBYAUDITASSETID_FAILURE, error };
  }
}

function _deleteReconAsset(id) {
  return (dispatch) => {
    dispatch(request());

    return assetService._deleteReconAsset(id).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.DELETEASSETBENEFICIARY_REQUEST };
  }
  function success() {
    return { type: assetConstants.DELETEASSETBENEFICIARY_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.DELETEASSETBENEFICIARY_FAILURE, error };
  }
}
function _deleteAssetBeneficiary(assetBeneficiary) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      ._deleteAssetBeneficiary(assetBeneficiary)
      .then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.DELETEASSETBENEFICIARY_REQUEST };
  }
  function success() {
    return { type: assetConstants.DELETEASSETBENEFICIARY_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.DELETEASSETBENEFICIARY_FAILURE, error };
  }
}

function addUsufructBeneficiary(usufructBeneficiary) {
  return (dispatch) => {
    dispatch(request());
    return assetService
      .addUsufructBeneficiary(usufructBeneficiary)
      .then(dispatch(success(usufructBeneficiary)));
  };

  function request() {
    return { type: assetConstants.ADDASSETUSUFRUCTBENEFICIARY_REQUEST };
  }
  function success(usufructBeneficiary) {
    return {
      type: assetConstants.ADDASSETUSUFRUCTBENEFICIARY_SUCCESS,
      usufructBeneficiary,
    };
  }
  function failure(error) {
    return { type: assetConstants.ADDASSETUSUFRUCTBENEFICIARY_FAILURE, error };
  }
}

function addAwardBeneficiary(assetBeneficiary) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      .addAwardBeneficiary(assetBeneficiary)
      .then(dispatch(success(assetBeneficiary)));
  };

  function request() {
    return { type: assetConstants.ADDASSETBENEFICIARY_REQUEST };
  }
  function success(assetBeneficiary) {
    return {
      type: assetConstants.ADDASSETBENEFICIARY_SUCCESS,
      assetBeneficiary,
    };
  }
  function failure(error) {
    return { type: assetConstants.ADDASSETBENEFICIARY_FAILURE, error };
  }
}

function updateUsufructBeneficiary(usufructBeneficiary) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      .updateUsufructBeneficiary(usufructBeneficiary)
      .then(dispatch(success(usufructBeneficiary)));
  };

  function request() {
    return { type: assetConstants.UPDATEUSUFRUCTBENEFICIARY_REQUEST };
  }
  function success(usufructBeneficiary) {
    return {
      type: assetConstants.UPDATEUSUFRUCTBENEFICIARY_SUCCESS,
      usufructBeneficiary,
    };
  }
  function failure(error) {
    return { type: assetConstants.UPDATEUSUFRUCTBENEFICIARY_FAILURE, error };
  }
}

function updateAwardBeneficiary(assetBeneficiary) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      .updateAwardBeneficiary(assetBeneficiary)
      .then(dispatch(success(assetBeneficiary)));
  };

  function request() {
    return { type: assetConstants.UPDATEAWARDASSETBENEFICIARY_REQUEST };
  }
  function success(assetBeneficiary) {
    return {
      type: assetConstants.UPDATEAWARDASSETBENEFICIARY_SUCCESS,
      assetBeneficiary,
    };
  }
  function failure(error) {
    return { type: assetConstants.UPDATEAWARDASSETBENEFICIARY_FAILURE, error };
  }
}

function add(asset) {
  return (dispatch) => {
    dispatch(request());

    return assetService.add(asset).then(dispatch(success(asset)));
  };

  function request() {
    return { type: assetConstants.ADD_REQUEST };
  }
  function success(asset) {
    return { type: assetConstants.ADD_SUCCESS, asset };
  }
  function failure(error) {
    return { type: assetConstants.ADD_FAILURE, error };
  }
}
function updateReconAsset(assetRecon) {
  return (dispatch) => {
    dispatch(request());

    return assetService.update(asset).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: assetConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.UPDATE_FAILURE, error };
  }
}

function update(asset) {
  return (dispatch) => {
    dispatch(request());

    return assetService.update(asset).then(dispatch(success(asset)));
  };

  function request() {
    return { type: assetConstants.UPDATE_REQUEST };
  }
  function success() {
    return { type: assetConstants.UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.UPDATE_FAILURE, error };
  }
}

function deleteUsufructBeneficiary(id) {
  return (dispatch) => {
    dispatch(request());
    return assetService.deleteUsufructBeneficiary(id).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.DELETEUSUFRUCTBENEFICIARY_REQUEST };
  }
  function success() {
    return { type: assetConstants.DELETEUSUFRUCTBENEFICIARY_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.DELETEUSUFRUCTBENEFICIARY_FAILURE, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request());
    return assetService._delete(id).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.DELETE_REQUEST };
  }
  function success() {
    return { type: assetConstants.DELETE_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.DELETE_FAILURE, error };
  }
}

function sendValuerEmail(asset) {
  return (dispatch) => {
    dispatch(request());

    return assetService.sendValuerEmail(asset).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.SENDVALUEREMAIL_REQUEST };
  }
  function success() {
    return { type: assetConstants.SENDVALUEREMAIL_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.SENDVALUEREMAIL_FAILURE, error };
  }
}

function sendAuctioneerEmail(asset) {
  return (dispatch) => {
    dispatch(request());

    return assetService.sendAuctioneerEmail(asset).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.SENDAUCTIONEEREMAIL_REQUEST };
  }
  function success() {
    return { type: assetConstants.SENDAUCTIONEEREMAIL_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.SENDAUCTIONEEREMAIL_FAILURE, error };
  }
}

function sendBuyerEmail(asset) {
  return (dispatch) => {
    dispatch(request());

    return assetService.sendBuyerEmail(asset).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.SENDBUYEREMAIL_REQUEST };
  }
  function success() {
    return { type: assetConstants.SENDBUYEREMAIL_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.SENDBUYEREMAIL_FAILURE, error };
  }
}

function addSignedOTPAudit(asset) {
  return (dispatch) => {
    dispatch(request());

    return assetService.addSignedOTPAudit(asset).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.ADDSIGNEDOTPAUDIT_REQUEST };
  }
  function success() {
    return { type: assetConstants.ADDSIGNEDOTPAUDIT_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.ADDSIGNEDOTPAUDIT_FAILURE, error };
  }
}

function addActionDateAudit(asset) {
  return (dispatch) => {
    dispatch(request());

    return assetService.addActionDateAudit(asset).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.ADDAUCTIONDATE_REQUEST };
  }
  function success() {
    return { type: assetConstants.ADDAUCTIONDATE_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.ADDAUCTIONDATE_FAILURE, error };
  }
}

function addValuationAudit(asset) {
  return (dispatch) => {
    dispatch(request());

    return assetService.addValuationAudit(asset).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.ADDVALUATUIONAUDIT_REQUEST };
  }
  function success() {
    return { type: assetConstants.ADDVALUATUIONAUDIT_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.ADDVALUATUIONAUDIT_FAILURE, error };
  }
}
function saveAssetReconAmount(reconAsset) {
  return (dispatch) => {
    dispatch(request());

    return assetService
      .saveAssetReconAmount(reconAsset)
      .then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.ADDSALERESULT_REQUEST };
  }
  function success() {
    return { type: assetConstants.ADDSALERESULT_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.ADDSALERESULT_FAILURE, error };
  }
}

function addSaleResultAudit(asset) {
  return (dispatch) => {
    dispatch(request());

    return assetService.addSaleResultAudit(asset).then(dispatch(success()));
  };

  function request() {
    return { type: assetConstants.ADDSALERESULT_REQUEST };
  }
  function success() {
    return { type: assetConstants.ADDSALERESULT_SUCCESS };
  }
  function failure(error) {
    return { type: assetConstants.ADDSALERESULT_FAILURE, error };
  }
}
