import React, { useMemo, ReactNode } from "react";
import { connect } from "react-redux";

interface DeveloperRendererProps {
  children: ReactNode;
  email?: string;
}

const DeveloperRenderer: React.FC<DeveloperRendererProps> = ({
  children,
  email,
}: DeveloperRendererProps) => {
  const renderedChildren = useMemo(() => {
    return email === "john@fennecgroup.co.za" ? children : null;
  }, [children]);

  return <>{renderedChildren}</>;
};

function mapStateToProps(state) {
  const { email } = state.authentication.userDetails;
  return {
    email,
  };
}

export default connect(mapStateToProps)(DeveloperRenderer);
