import React, { Component } from "react";
import "../gridButtonCss.css";
import { connect } from "react-redux";
import _ from "lodash";

class SystemViewRenderer extends Component {
  constructor(props) {
    super(props);

    var hasAccess = false;

    if (!props.system) {
      hasAccess = true;
    } else if (props.system.length) {
      if (_.some(props.system, (x) => x === props.systemType)) {
        hasAccess = true;
      }
    } else if (props.system == props.systemType) {
      hasAccess = true;
    }

    this.state = {
      hasAccess: hasAccess,
    };
  }

  render() {
    let view;
    if (this.state.hasAccess) {
      view =
        this.props
          .children; /*<span className={this.props.className}>{this.props.children}</span>;*/
    } else {
      view = <span className="d-none">{this.props.children}</span>;
    }

    return view;
  }
}

SystemViewRenderer.displayName = "SystemViewRenderer";

function mapStateToProps(state) {
  const { systemType } = state.authentication;
  return {
    systemType,
  };
}

export default connect(mapStateToProps)(SystemViewRenderer);
