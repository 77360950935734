import { NotificationManager } from "react-notifications";
import axios from "axios";
import { authHeader } from "./auth-header";
import { tokenStore } from "../_helpers";

export { service, serviceResponse };

const HttpCodes = {
  success: 200,
  Unauthorised: 401,
  // etc
};
const service = {
  handleResponse,
  handleError,
  fetch: {
    get: fetchGet,
    post: fetchPost,
    postRaw: fetchRawPost,
    delete: fetchDelete,
    put: fetchPut,
  },
};

const serviceResponse = {
  handleResponse,
  handleError,
  fetch: {
    get: fetchGet,
    post: fetchPost,
  },
};

function getRefreshToken() {
  return localStorage.getItem("refreshToken");
}

function getUserToken() {
  return localStorage.getItem("userToken");
}

function saveUserToken(token) {
  localStorage.setItem("userToken", token);
}

function saveRefreshToken(refreshToken) {
  localStorage.setItem("refreshToken", refreshToken);
}

async function refreshToken(userToken, refreshToken) {
  return fetch("/account/refreshToken", {
    method: "POST",
    body: `token=${encodeURIComponent(
      userToken,
    )}&refreshToken=${encodeURIComponent(refreshToken)}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then(handleResponse, handleError);
}

function handleResponse(response, callback, args) {
  return new Promise(async (resolve, reject) => {
    if (
      response.headers["accesserror"] !== undefined &&
      JSON.parse(response.headers["accesserror"])
    ) {
      alert(response.headers["errorresponse"]);
      if (!!response.headers.location) {
        window.location.href = response.headers.location;
        resolve();
      }
    }

    if (response.redirected) {
      window.location.href = response.url;

      resolve();
    }

    /*if(response.headers.location !== undefined){
      window.location.href = response.headers.location
      alert("Multiple user logins detected, your user will be logged out");
      resolve()
    }*/

    if (response.headers["user-token"]) {
      var token = response.headers["user-token"];
      tokenStore.saveUserToken(token);
    }

    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then((json) => {
          resolve(json);
        });
      } else {
        resolve();
      }
    } else if (response.status === 200) {
      resolve(response.data);
    } else if (response.status === 204) {
      resolve();
    } else {
      //throw Error(response.statusText);
      // return error message from response body
      var title = "Error";
      var message = "Oops..! An error has occured.";
      //
      if (response.statusText && response.statusText !== "") {
        message = response.statusText;
      }

      response.text().then((text) => {
        if (text !== "") {
          message = text;
        }
        //
        try {
          var modelState = JSON.parse(message);
          //message = '';
          for (var key in modelState) {
            NotificationManager.error(modelState[key], title, 60000);
            //throw Error(modelState[key]);
            //message += '\r\n' + modelState[key] + '\r\n';
          }
        } catch {
          //NotificationManager.error(message, title, 60000);
          //throw Error(message);
        }

        reject(message);
      });
      //.catch(err => {
      //  throw Error(err);
      //});
    }
  });
}

async function parseTextBlob(blob) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsText(blob);
  });
}

function handleError(error, callback, args) {
  var title = "Error";

  /*  if(error.request.response !== undefined){
    //errorMessage = JSON.parse(error.response)
    NotificationManager.warning(error.response.data.message, title, 60000);
  }*/

  var message = "Oops..! An error has occured.";
  try {
    var response = error.response;

    if (response) {
      //
      if (response.status === 401 && response.headers["token-expired"]) {
        //
        //var refreshResponse = await userService.refreshToken(tokenStore.getUserToken(), tokenStore.getRefreshToken()); //refreshToken(getUserToken(), getRefreshToken());
        // if (!refreshResponse.ok) {
        //     return response; //failed to refresh so return original 401 response
        // }
        // var jsonRefreshResponse = await refreshResponse.json(); //read the json with the new tokens
        // saveUserToken(jsonRefreshResponse.token);
        // saveRefreshToken(jsonRefreshResponse.refreshToken);
        // // if (tokenStore.getUserToken() && !tokenStore.isRefreshTokenExpired())
        // // {
        // //   return userService.refreshToken(tokenStore.getUserToken(), tokenStore.getRefreshToken())
        // //   .then((jsonRefreshResponse) => {
        // //     //
        // //     tokenStore.saveUserToken(jsonRefreshResponse.token);
        // //     tokenStore.saveRefreshToken(jsonRefreshResponse.refreshToken);
        // //     return callback(...args);
        // //     //return Promise.resolve(callback(...args))
        // //   }).catch(() =>{
        // //     tokenStore.clearTokens();
        // //     return Promise.reject('Error refreshing token');
        // //   })
        // // }
        //return callback(...args);
      }
    }

    if (error.response && error.response.data) {
      if (
        Object.prototype.toString.call(error.response.data) ===
        "[object String]"
      ) {
        message = error.response.data;
        return Promise.reject(message);
      }
      // DownloadURLFile returns blob type therefore we have to parse it to print the error message
      // so that the user can fix what their doing wrong
      if (error.response?.data instanceof Blob) {
        return parseTextBlob(error.response.data).then((error) => {
          try {
            let text;
            try {
              const json = JSON.parse(error);
              text = Object.values(json).join(", ");
            } catch {
              text = error;
            }
            NotificationManager.error(text, title, 60000);
            return Promise.reject(text);
          } catch {
            NotificationManager.error(
              "Failed to print correct error message",
              title,
              60000,
            );
            return Promise.reject(error);
          }
        });
      }
      var errorValues = Object.values(error.response.data);
      if (errorValues.length === 0) {
        NotificationManager.error(message, title, 60000);
      }
      for (let errorValue of errorValues) {
        if (Array.isArray(errorValue)) {
          errorValue.forEach((message) => {
            NotificationManager.error(message, title, 60000);
          });
        } else {
          NotificationManager.error(errorValue, title, 60000);
        }
      }

      return Promise.reject(message);
    }

    var modelState = JSON.parse(error);

    if (modelState.message) {
      NotificationManager.error(modelState.message, title, 60000);
      return Promise.reject(modelState.message);
    }

    //message = '';
    //for (var key in modelState) {
    //  NotificationManager.error(modelState[key], title, 60000);
    //  //throw Error(modelState[key]);
    //  //message += '\r\n' + modelState[key] + '\r\n';
    //}
  } catch {
    NotificationManager.error(error, title, 60000);
    //throw Error(message);
  }

  //NotificationManager.error(error && error.message, 'Error', 30000);
  return Promise.reject(error && error.message);
}

function processHeaders(config) {
  var _headers = authHeader();
  if (config.headers) {
    config.headers = { ...config.headers, ..._headers };
  } else {
    config.headers = _headers;
  }
}

function fetchGet(url, config = {}) {
  processHeaders(config);

  return (
    axios
      .get(url, config)
      //.then(handleResponse)
      .then((response) => handleResponse(response, fetchGet, [url, config]))
      .catch((error) => handleError(error, fetchGet, [url, config]))
  );
}

function fetchPost(url, data, config = {}) {
  processHeaders(config);

  return axios
    .post(url, data, config)
    .then((response) => handleResponse(response, fetchPost, [url, config]))
    .catch((error) => handleError(error, fetchPost, [url, config]));
  //.then(handleResponse)
  //.catch(handleError);
}

function fetchRawPost(url, data, config = {}) {
  return axios
    .post(url, data, config)
    .then((response) => handleResponse(response, fetchPost, [url, config]))
    .catch((error) => handleError(error, fetchPost, [url, config]));
  //.then(handleResponse)
  //.catch(handleError);
}

function fetchPut(url, data, config = {}) {
  processHeaders(config);

  return axios
    .put(url, data, config)
    .then((response) => handleResponse(response, fetchPut, [url, config]))
    .catch((error) => handleError(error, fetchPut, [url, config]));
  // .then(handleResponse)
  // .catch(handleError);
}

function fetchDelete(url, data, config = {}) {
  processHeaders(config);

  config.data = data;

  return axios
    .delete(url, config)
    .then((response) => handleResponse(response, fetchDelete, [url, config]))
    .catch((error) => handleError(error, fetchDelete, [url, config]));
  // .then(handleResponse)
  // .catch(handleError);
}
