import { auditTrailConstants } from '../_constants';
import { auditTrailService } from '../_services';

export const auditTrailActions = {
  getList
};

function getList(skip, take, searchString, fromSearchDate, toSearchDate, intermediaryId) {
  return dispatch => {
    dispatch(request());
    return auditTrailService.getList(skip, take, searchString, fromSearchDate, toSearchDate, intermediaryId)
      .then(
      auditTrails => dispatch(success(auditTrails)),
      );
  };

  function request() { return { type: auditTrailConstants.GETLIST_REQUEST } }
  function success(auditTrails) { return { type: auditTrailConstants.GETLIST_SUCCESS, auditTrails } }
  function failure(error) { return { type: auditTrailConstants.GETLIST_FAILURE, error } }
}
