export const insolventConstants = {
    GETLIST_REQUEST: 'INSOLVENTS_GETLIST_REQUEST',
    GETLIST_SUCCESS: 'INSOLVENTS_GETLIST_SUCCESS',
    GETLIST_FAILURE: 'INSOLVENTS_GETLIST_FAILURE',

    GETBYID_REQUEST: 'INSOLVENTS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'INSOLVENTS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'INSOLVENTS_GETBYID_FAILURE',

    ISDECEASEDORDINARYRESIDENT_REQUEST: 'ISDECEASEDORDINARYRESIDENT_REQUEST',
    ISDECEASEDORDINARYRESIDENT_SUCCESS: 'ISDECEASEDORDINARYRESIDENT_SUCCESS',
    ISDECEASEDORDINARYRESIDENT_FAILURE: 'ISDECEASEDORDINARYRESIDENT_FAILURE',

    ADD_REQUEST: 'INSOLVENTS_ADD_REQUEST',
    ADD_SUCCESS: 'INSOLVENTS_ADD_SUCCESS',
  ADD_FAILURE: 'INSOLVENTS_ADD_FAILURE',

  GETDIRECTORMEMBERSBYCOMPANYID_REQUEST: 'INSOLVENTS_GETDIRECTORMEMBERSBYCOMPANYID_REQUEST',
  GETDIRECTORMEMBERSBYCOMPANYID_SUCCESS: 'INSOLVENTS_GETDIRECTORMEMBERSBYCOMPANYID_SUCCESS',
  GETDIRECTORMEMBERSBYCOMPANYID_FAILURE: 'INSOLVENTS_GETDIRECTORMEMBERSBYCOMPANYID_FAILURE',


  GETDECEASEDSPOUSEBYINSOLVENTID_REQUEST: 'INSOLVENTS_GETDECEASEDSPOUSEBYINSOLVENTID_REQUEST',
  GETDECEASEDSPOUSEBYINSOLVENTID_SUCCESS: 'INSOLVENTS_GETDECEASEDSPOUSEBYINSOLVENTID_SUCCESS',
  GETDECEASEDSPOUSEBYINSOLVENTID_FAILURE: 'INSOLVENTS_GETDECEASEDSPOUSEBYINSOLVENTID_FAILURE',


  
  UPDATEDECEASEDSPOUSE_REQUEST: 'INSOLVENTS_UPDATEDECEASEDSPOUSE_REQUEST',
  UPDATEDECEASEDSPOUSE_SUCCESS: 'INSOLVENTS_UPDATEDECEASEDSPOUSE_SUCCESS',
  UPDATEDECEASEDSPOUSE_FAILURE: 'INSOLVENTS_UPDATEDECEASEDSPOUSE_FAILURE',

  DELETEDECEASEDSPOUSE_REQUEST: 'INSOLVENTS_DELETEDECEASEDSPOUSE_REQUEST',
  DELETEDECEASEDSPOUSE_SUCCESS: 'INSOLVENTS_DELETEDECEASEDSPOUSE_SUCCESS',
  DELETEDECEASEDSPOUSE_FAILURE: 'INSOLVENTS_DELETEDECEASEDSPOUSE_FAILURE',

  

  ADDDECEASEDSPOUSE_REQUEST: 'INSOLVENTS_ADDDECEASEDSPOUSE_REQUEST',
  ADDDECEASEDSPOUSE_SUCCESS: 'INSOLVENTS_ADDDECEASEDSPOUSE_SUCCESS',
  ADDDECEASEDSPOUSE_FAILURE: 'INSOLVENTS_ADDDECEASEDSPOUSE_FAILURE',
    
  GETDECEASEDCHILDBYINSOLVENTID_REQUEST: 'INSOLVENTS_GETDECEASEDCHILDBYINSOLVENTID_REQUEST',
  GETDECEASEDCHILDBYINSOLVENTID_SUCCESS: 'INSOLVENTS_GETDECEASEDCHILDBYINSOLVENTID_SUCCESS',
  GETDECEASEDCHILDBYINSOLVENTID_FAILURE: 'INSOLVENTS_GETDECEASEDCHILDBYINSOLVENTID_FAILURE',


  ADDDECEASEDCHILD_REQUEST: 'INSOLVENTS_ADDDECEASEDCHILD_REQUEST',
  ADDDECEASEDCHILD_SUCCESS: 'INSOLVENTS_ADDDECEASEDCHILD_SUCCESS',
  ADDDECEASEDCHILD_FAILURE: 'INSOLVENTS_ADDDECEASEDCHILD_FAILURE',

  

  UPDATEDECEASEDCHILD_REQUEST: 'INSOLVENTS_UPDATEDECEASEDCHILD_REQUEST',
  UPDATEDECEASEDCHILD_SUCCESS: 'INSOLVENTS_UPDATEDECEASEDCHILD_SUCCESS',
  UPDATEDECEASEDCHILD_FAILURE: 'INSOLVENTS_UPDATEDECEASEDCHILD_FAILURE',

    UPDATE_REQUEST: 'INSOLVENTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'INSOLVENTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'INSOLVENTS_UPDATE_FAILURE',

    DELETEDECEASEDCHILD_REQUEST: 'INSOLVENTS_DELETEDECEASEDCHILD_REQUEST',
    DELETEDECEASEDCHILD_SUCCESS: 'INSOLVENTS_DELETEDECEASEDCHILD_SUCCESS',
  DELETEDECEASEDCHILD_FAILURE: 'INSOLVENTS_DELETEDECEASEDCHILD_FAILURE',

  DELETE_REQUEST: 'INSOLVENTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'INSOLVENTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'INSOLVENTS_DELETE_FAILURE',

  

  UPLOADEDDECEASEDIDAUDIT_REQUEST: 'INSOLVENTS_UPLOADEDDECEASEDIDAUDIT_REQUEST',
  UPLOADEDDECEASEDIDAUDIT_SUCCESS: 'INSOLVENTS_UPLOADEDDECEASEDIDAUDIT_SUCCESS',
  UPLOADEDDECEASEDIDAUDIT_FAILURE: 'INSOLVENTS_UPLOADEDDECEASEDIDAUDIT_FAILURE',

  UPLOADEDMARRIAGECERFTICATEAUDIT_REQUEST: 'INSOLVENTS_UPLOADEDMARRIAGECERFTICATEAUDIT_REQUEST',
  UPLOADEDMARRIAGECERFTICATEAUDIT_SUCCESS: 'INSOLVENTS_UPLOADEDMARRIAGECERFTICATEAUDIT_SUCCESS',
  UPLOADEDMARRIAGECERFTICATEAUDIT_FAILURE: 'INSOLVENTS_UPLOADEDMARRIAGECERFTICATEAUDIT_FAILURE',

  UPLOADEDDIVORCEORDERORSETTLEMENTAGREEMENTAUDIT_REQUEST: 'INSOLVENTS_UPLOADEDDIVORCEORDERORSETTLEMENTAGREEMENTAUDIT_REQUEST',
  UPLOADEDDIVORCEORDERORSETTLEMENTAGREEMENTAUDIT_SUCCESS: 'INSOLVENTS_UPLOADEDDIVORCEORDERORSETTLEMENTAGREEMENTAUDIT_SUCCESS',
  UPLOADEDDIVORCEORDERORSETTLEMENTAGREEMENTAUDIT_FAILURE: 'INSOLVENTS_UPLOADEDDIVORCEORDERORSETTLEMENTAGREEMENTAUDIT_FAILURE',

  UPLOADEDDECLARATIONOFMARRIAGEAUDIT_REQUEST: 'INSOLVENTS_UPLOADEDDECLARATIONOFMARRIAGEAUDIT_REQUEST',
  UPLOADEDDECLARATIONOFMARRIAGEAUDIT_SUCCESS: 'INSOLVENTS_UPLOADEDDECLARATIONOFMARRIAGEAUDIT_SUCCESS',
  UPLOADEDDECLARATIONOFMARRIAGEAUDIT_FAILURE: 'INSOLVENTS_UPLOADEDDECLARATIONOFMARRIAGEAUDIT_FAILURE',

  UPLOADEDINCOMETAXREFAUDIT_REQUEST: 'INSOLVENTS_UPLOADEDINCOMETAXREFAUDIT_REQUEST',
  UPLOADEDINCOMETAXREFAUDIT_SUCCESS: 'INSOLVENTS_UPLOADEDINCOMETAXREFAUDIT_SUCCESS',
  UPLOADEDINCOMETAXREFAUDIT_FAILURE: 'INSOLVENTS_UPLOADEDINCOMETAXREFAUDIT_FAILURE',

  UPLOADEDANTENUPTIALCONTRACTAUDIT_REQUEST: 'INSOLVENTS_UPLOADEDANTENUPTIALCONTRACTAUDIT_REQUEST',
  UPLOADEDANTENUPTIALCONTRACTAUDIT_SUCCESS: 'INSOLVENTS_UPLOADEDANTENUPTIALCONTRACTAUDIT_SUCCESS',
  UPLOADEDANTENUPTIALCONTRACTAUDIT_FAILURE: 'INSOLVENTS_UPLOADEDANTENUPTIALCONTRACTAUDIT_FAILURE',

  ADDEDLIABILITYAUDIT_REQUEST: 'INSOLVENTS_ADDEDLIABILITYAUDIT_REQUEST',
  ADDEDLIABILITYAUDIT_SUCCESS: 'INSOLVENTS_ADDEDLIABILITYAUDIT_SUCCESS',
  ADDEDLIABILITYAUDIT_FAILURE: 'INSOLVENTS_ADDEDLIABILITYAUDIT_FAILURE',

  UPDATEDLIABILITYAUDIT_REQUEST: 'INSOLVENTS_UPDATEDLIABILITYAUDIT_REQUEST',
  UPDATEDLIABILITYAUDIT_SUCCESS: 'INSOLVENTS_UPDATEDLIABILITYAUDIT_SUCCESS',
  UPDATEDLIABILITYAUDIT_FAILURE: 'INSOLVENTS_UPDATEDLIABILITYAUDIT_FAILURE'
};
