//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from "../_helpers";

export const assetService = {
  getList,
  getByMatterID,
  getReconAssetByID,
  getUsufructBeneficiaries,
  getDropDownListByMatterID,
  getCGTByMatterID,
  getByAssetID,
  getByAuditAssetID,
  getReconItemsByAssetID,
  cancelSale,
  add,
  update,
  _delete,
  _deleteReconAsset,
  sendValuerEmail,
  sendAuctioneerEmail,
  sendBuyerEmail,
  addSignedOTPAudit,
  addUsufructBeneficiary,
  addActionDateAudit,
  addValuationAudit,
  addSaleResultAudit,
  saveAssetReconAmount,
  getAwardListByAssetID,
  addAwardBeneficiary,
  addBequestBeneficiary,
  getBequestBeneficiaries,
  updateBequestBeneficiary,
  updateUsufructBeneficiary,
  updateAwardBeneficiary,
  updateReconAssetAmount,
  _deleteAssetBeneficiary,
  deleteUsufructBeneficiary,
  getAssetAmounts,
  saveAssetAmount,
  deleteAssetAmount,
  getAssetAllocationByBeneficiaryID,
  searchMatterAssetList,
};

function updateReconAssetAmount(asset) {
  return service.fetch.post(`/api/asset/updateReconAssetAmount`, asset);
}
function searchMatterAssetList(matterID, searchString) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/searchMatterAssetList`, {
      matterID,
      searchString,
    }),
  );
}

function getList(skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var assets = fetch(serviceBuild.buildUrl(`/api/asset/getlist`, { skip, take}), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return assets;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getlist`, { skip, take }),
  );
}

function getAwardListByAssetID(id, skip, take) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getawardlistbyassetid`, {
      id,
      skip,
      take,
    }),
  );
}

function getAssetAllocationByBeneficiaryID(matterID, beneficiaryID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getAssetAllocationByBeneficiaryID`, {
      matterID,
      beneficiaryID,
    }),
  );
}

function addUsufructBeneficiary(usufructBeneficiary) {
  return service.fetch.post(
    `/api/asset/addUsufructBeneficiary`,
    usufructBeneficiary,
  );
}

/////////////////////////////
//Bequest
/////////////////////////////

//Create
function addBequestBeneficiary(bequestBeneficiary) {
  return service.fetch.post(
    `/api/asset/addBequestBeneficiary`,
    bequestBeneficiary,
  );
}

//Read
function getBequestBeneficiaries(assetID, beneficiaryID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getBequestBeneficiaries`, {
      assetID,
      beneficiaryID,
    }),
  );
}

//Update
function updateBequestBeneficiary(bequestBeneficiary) {
  return service.fetch.post(
    `/api/asset/updateBequestBeneficiary`,
    bequestBeneficiary,
  );
}

//Delete
function deleteBequestBeneficiary(id) {
  return service.fetch.delete(
    `/api/asset/deleteBequestBeneficiary`,
    JSON.stringify(id),
  );
}

/////////////////////////////

function addAwardBeneficiary(assetBeneficiary) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(asset)
  //};

  //var result = fetch(`/api/asset/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/asset/addAssetBeneficiary`, assetBeneficiary);
}

function updateAwardBeneficiary(assetBeneficiary) {
  return service.fetch.post(
    `/api/asset/updateAssetBeneficiary`,
    assetBeneficiary,
  );
}

function _deleteAssetBeneficiary(assetBeneficiary) {
  return service.fetch.delete(
    `/api/asset/_deleteAssetBeneficiary`,
    JSON.stringify(assetBeneficiary),
  );
}

function getCGTByMatterID(matterID, searchString, cgtType) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getcgtbymatterid`, {
      matterID,
      searchString,
      cgtType,
    }),
  );
}

function getDropDownListByMatterID(matterId) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var assets = fetch(serviceBuild.buildUrl(`/api/asset/getlistbymatterid`, { skip, take, searchString, matterId }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);

  //return assets;

  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getDropDownListByMatterID`, { matterId }),
  );
}

function getUsufructBeneficiaries(assetID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getUsufructBeneficiaries`, { assetID }),
  );
}
function getReconAssetByID(id) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getReconAssetByID`, { id }),
  );
}
function getByMatterID(skip, take, searchString, matterId) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var assets = fetch(serviceBuild.buildUrl(`/api/asset/getlistbymatterid`, { skip, take, searchString, matterId }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);

  //return assets;

  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getlistbymatterid`, {
      skip,
      take,
      searchString,
      matterId,
    }),
  );
}

function getByAssetID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var result = fetch(serviceBuild.buildUrl(`/api/asset/getbyassetid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getbyassetid`, { id }),
  );
}

function getReconItemsByAssetID(assetID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getReconItemsByAssetID`, { assetID }),
  );
}
function getByAuditAssetID(id, skip, take) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()

  //};

  //var result = fetch(serviceBuild.buildUrl(`/api/asset/getbyassetauditid`, { id, skip, take }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/getbyassetauditid`, { id, skip, take }),
  );
}

function add(asset) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(asset)
  //};

  //var result = fetch(`/api/asset/add`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/asset/add`, asset);
}

function update(asset) {
  //const requestOptions = {
  //  method: 'PUT',
  //  headers: authHeader(),
  //  body: JSON.stringify(asset)
  //};

  //var result = fetch(`/api/asset/update`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/asset/update`, asset);
}

function deleteUsufructBeneficiary(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/asset/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.delete(
    `/api/asset/deleteUsufructBeneficiary`,
    JSON.stringify(id),
  );
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/asset/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.delete(`/api/asset/_delete`, JSON.stringify(id));
}
function _deleteReconAsset(id) {
  return service.fetch.delete(
    `/api/asset/_deleteReconAsset`,
    JSON.stringify(id),
  );
}
function sendValuerEmail(asset) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(asset)
  //};

  //var result = fetch(`/api/asset/sendvalueremail`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/asset/sendvalueremail`, asset);
}

function updateUsufructBeneficiary(usufructBeneficiary) {
  return service.fetch.post(
    `/api/asset/updateUsufructBeneficiary`,
    usufructBeneficiary,
  );
}

function sendAuctioneerEmail(asset) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(asset)
  //};

  //var result = fetch(`/api/asset/sendauctioneeremail`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/asset/sendauctioneeremail`, asset);
}

function sendBuyerEmail(asset) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(asset)
  //};

  //var result = fetch(`/api/asset/sendbuyeremail`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/asset/sendbuyeremail`, asset);
}

function cancelSale(assetID) {
  return service.fetch.post(`/api/asset/cancelSale`, assetID);
}

function addSignedOTPAudit(asset) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(asset)
  //};

  //var result = fetch(`/api/asset/addsignedotpaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/asset/addsignedotpaudit`, asset);
}

function addValuationAudit(asset) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(asset)
  //};

  //var result = fetch(`/api/asset/addvaluationaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/asset/addvaluationaudit`, asset);
}

function addActionDateAudit(asset) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(asset)
  //};

  //var result = fetch(`/api/asset/addauctionaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/asset/addauctionaudit`, asset);
}
function saveAssetReconAmount(reconAsset) {
  return service.fetch.post(`/api/asset/saveAssetReconAmount`, reconAsset);
}
function addSaleResultAudit(asset) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(asset)
  //};

  //var result = fetch(`/api/asset/addsaleresultaudit`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/asset/addsaleresultaudit`, asset);
}

function getAssetAmounts(assetID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/asset/GetAssetAmounts`, { assetID }),
  );
}

function saveAssetAmount(model) {
  return service.fetch.post(`/api/asset/SaveAssetAmount`, model);
}

function deleteAssetAmount(assetAmountID) {
  return service.fetch.post(`/api/asset/DeleteAssetAmount`, assetAmountID);
}
