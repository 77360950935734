//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from '../_helpers';

export const auditTrailService = {
  getList,
  getByActionID
};

function getList(skip, take, searchString, fromSearchDate, toSearchDate, intermediaryId) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var auditTrails = fetch(serviceBuild.buildUrl(`/api/audittrail/getlist`, { skip, take, searchString, fromSearchDate, toSearchDate, intermediaryId}), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return auditTrails;
  return service.fetch.get(serviceBuild.buildUrl(`/api/audittrail/getlist`, { skip, take, searchString, fromSearchDate, toSearchDate, intermediaryId }));
}

 function getByActionID(actionID, skip=0, take=5 , matterID = 0){
   return service.fetch.get(serviceBuild.buildUrl(`/api/audittrail/getAuditTrailByActionID`, {actionID, skip, take, matterID }));
 }

