import { service } from "../_helpers";

export const auditorService = {
  getByMatterID,
  add,
  update,
  _delete,
};

function getByMatterID(id) {
  return service.fetch.post(`/api/auditor/getByMatterId`, JSON.stringify(id));
}

function add(auditor) {
  return service.fetch.post(`/api/auditor/add`, auditor);
}

function update(auditor) {
  return service.fetch.post(`/api/auditor/update`, auditor);
}

function _delete(id) {
  return service.fetch.delete(`/api/auditor/delete`, JSON.stringify(id));
}
