import { SystemTypeEnum } from "../views/Enums/SystemTypeEnum";

const LiquidationAccount = { key: 1, Name: "Liquidation account" };
const NewPageRecapitulationStatement = {
  key: 2,
  Name: "Recapitulation Statement",
};
const NewPageDistributionAccount = { key: 3, Name: "Distribution Account" };
const NewPageIncomeAndExpenditureAccount = {
  key: 4,
  Name: "Income and Expenditure Account",
};
const NewPageFiduciaryAccount = { key: 5, Name: "Fiduciary Account" };
const NewPageEstateDutyAddendum = { key: 6, Name: "Estate Duty Addendum" };
const FeesVoucherNumber = {
  key: 7,
  Name: "Show voucher number on Master and Executor fees",
};
const NewPageAssets = {
  key: 8,
  Name: "Liquidation Account subheading: Assets",
};
const NewPageLiabilities = {
  key: 9,
  Name: "Liquidation Account subheading: Liabilities",
};
const NewPageApportionmentAccount = {
  key: 10,
  Name: "Estate Duty Addendum subheading: Apportionment Account",
};
const HeaderList = { key: 11, Name: "Show Header as List" };
const IncomeExpensesList = {
  key: 12,
  Name: "Show income and expenses in separate tax years under the income and expenditure account section",
};
const FlowConsultIncludeLogo = {
  key: 13,
  Name: "Include Company Logo on Flow Consult report",
};
const FlowConsultShowHeader = { key: 14, Name: "Show Flow Consult header" };
const FlowConsultShowCertificate = {
  key: 15,
  Name: "Include company details in Executor Certificate",
};
const TotalsColumn = { key: 16, Name: "Show Double Money Column" };
const NewPageClaimsAgainst = { key: 17, Name: "Claims against the Estate" };
const NewPageClaimsInFavor = {
  key: 18,
  Name: "Claims in favour of the Estate",
};
const ExcludeReferenceToFennecFlowInExecutorCertificate = {
  key: 19,
  Name: "Exclude reference to Fennec Flow",
};
const AssetsAlphabeticallySorted = {
  key: 20,
  Name: "Assets Alphabetically Sorted",
};
const ExcludeHalfShareFromRecapCashAvailableValue = {
  key: 21,
  Name: "Exclude Half Share from Cash Available for Distribution (Show Gross Cash Available for Distribution)",
};
const HideIncomeAndExpenditureVoucherNumbers = {
  key: 22,
  Name: "Hide Voucher Numbers in Income and Expenditure Account",
};
const HideIncomeAndExpenditure = {
  key: 23,
  Name: "Hide Income and Expenditure Account",
};
const HideTransactionsOnIncomeAndExpenditure = {
  key: 24,
  Name: "Hide transactions on Income and Expenditure Account",
};
const HideNullCurrencyForAssets = {
  key: 25,
  Name: "Exclude categories that contain no assets",
};
const ShowColumnBorders = { key: 26, Name: "Show Column Borders" };
const ExcludeAssetDescriptions = {
  key: 27,
  Name: "Exclude Asset Descriptions",
  SystemType: SystemTypeEnum.FlowConsult.id,
};
const ShowLiabilityValues = {
  key: 28,
  Name: "Activate L&D values for Master Fees, Estate Duty, Executor Fees in Recon Reports",
};

export const List = [
  LiquidationAccount,
  NewPageRecapitulationStatement,
  NewPageDistributionAccount,
  NewPageIncomeAndExpenditureAccount,
  NewPageFiduciaryAccount,
  NewPageEstateDutyAddendum,
  FeesVoucherNumber,
  NewPageAssets,
  NewPageLiabilities,
  NewPageApportionmentAccount,
  HeaderList,
  IncomeExpensesList,
  FlowConsultIncludeLogo,
  FlowConsultShowHeader,
  FlowConsultShowCertificate,
  TotalsColumn,
  NewPageClaimsAgainst,
  NewPageClaimsInFavor,
  ExcludeReferenceToFennecFlowInExecutorCertificate,
  AssetsAlphabeticallySorted,
  ExcludeHalfShareFromRecapCashAvailableValue,
  HideIncomeAndExpenditureVoucherNumbers,
  HideIncomeAndExpenditure,
  HideTransactionsOnIncomeAndExpenditure,
  HideNullCurrencyForAssets,
  ShowColumnBorders,
  ExcludeAssetDescriptions,
  ShowLiabilityValues,
];

export const LDSelectionEnum = {
  LiquidationAccount,
  NewPageRecapitulationStatement,
  NewPageDistributionAccount,
  NewPageIncomeAndExpenditureAccount,
  NewPageFiduciaryAccount,
  NewPageEstateDutyAddendum,
  FeesVoucherNumber,
  NewPageAssets,
  NewPageLiabilities,
  NewPageApportionmentAccount,
  HeaderList,
  IncomeExpensesList,
  List,
  FlowConsultIncludeLogo,
  FlowConsultShowHeader,
  FlowConsultShowCertificate,
  TotalsColumn,
  NewPageClaimsAgainst,
  NewPageClaimsInFavor,
  ExcludeReferenceToFennecFlowInExecutorCertificate,
  AssetsAlphabeticallySorted,
  ExcludeHalfShareFromRecapCashAvailableValue,
  HideIncomeAndExpenditureVoucherNumbers,
  HideIncomeAndExpenditure,
  HideTransactionsOnIncomeAndExpenditure,
  HideNullCurrencyForAssets,
  ShowColumnBorders,
  ExcludeAssetDescriptions,
  ShowLiabilityValues,
};
