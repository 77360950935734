const _1st = { Name: '1st', ID: 1 };
const _7th = { Name: '7th', ID: 7 };
const _15th = { Name: '15th', ID: 15 };
const _20th = { Name: '20th', ID: 20 };
const _25th = { Name: '25th', ID: 25 };
const LastDay = { Name: 'Last Day', ID: 31 };

export const DayOfMonthEnum = {
  _1st,
  _7th,
  _15th,
  _20th,
  _25th,
  LastDay,
};
