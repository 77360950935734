//import config from 'config';
//import config from '../webpack.config';
import { service, serviceBuild } from "../_helpers";

export const beneficiaryService = {
  getList,
  getBequestBeneficiariesByMatterID,
  getAssetBeneficiariesByMatterID,
  getByMatterID,
  getHalfShareSpousesByMatterID,
  getByMatterIDIsForeign,
  getByBeneficiaryID,
  add,
  update,
  updateTree,
  getTree,
  updateBeneficiaries,
  enableBeneficiaryFreeResidueOverride,
  _delete,
  updateResident,
  isBenForeign,
  getBeneficiarySelectionData
};
function isBenForeign(matterID) {
  return service.fetch.post(
    `/api/beneficiary/isBenForeign`,
    JSON.stringify(matterID),
  );
}

function getBeneficiarySelectionData(matterID){
  var url = serviceBuild.buildUrl(`/api/beneficiary/getBeneficiarySelectionData`, { matterID });
  return service.fetch.get(url);
}

function getBequestBeneficiariesByMatterID(matterID) {
  return service.fetch.post(
    `/api/beneficiary/getBequestBeneficiariesByMatterID`,
    JSON.stringify(matterID),
  );
}

function getAssetBeneficiariesByMatterID(matterID) {
  return service.fetch.post(
    `/api/beneficiary/getAssetBeneficiariesByMatterID`,
    JSON.stringify(matterID),
  );
}
function getList(skip, take) {
  return service.fetch.get(`/api/beneficiary/getlist`);
}
function getHalfShareSpousesByMatterID(id) {
  return service.fetch.post(
    `/api/beneficiary/getHalfShareSpousesByMatterID`,
    JSON.stringify(id),
  );
}
function getByMatterID(id) {
  return service.fetch.post(
    `/api/beneficiary/getbymatterid`,
    JSON.stringify(id),
  );
}
function getByMatterIDIsForeign(id) {
  return service.fetch.post(
    `/api/beneficiary/getByMatterIDIsForeign`,
    JSON.stringify(id),
  );
}

function getByBeneficiaryID(id) {
  return service.fetch.post(
    `/api/beneficiary/getbybeneficiaryid`,
    JSON.stringify(id),
  );
}

function add(beneficiary) {
  return service.fetch.post(`/api/beneficiary/add`, beneficiary);
}

function update(beneficiary) {
  return service.fetch.post(`/api/beneficiary/update`, beneficiary);
}

function updateTree(matterID, tree) {
  var model = { matterID, tree: JSON.stringify(tree) };
  if (tree === null || tree === undefined) model.tree = null;

  return service.fetch.post(`/api/beneficiary/updateTree`, model);
}

function getTree(matterID) {
  var url = serviceBuild.buildUrl(`/api/beneficiary/getTree`, { matterID });
  return service.fetch.get(url);
}

function enableBeneficiaryFreeResidueOverride(matterID) {
  return service.fetch.post(
    `/api/beneficiary/enableBeneficiaryFreeResidueOverride`,
    matterID,
  );
}

function updateBeneficiaries(beneficiaries) {
  var model = { Beneficiaries: beneficiaries };
  return service.fetch.post(`/api/beneficiary/updateBeneficiaries`, model);
}

function updateResident(selected, id) {
  return service.fetch.post(`/api/beneficiary/updateResident`, {
    IsResident: selected,
    Id: id,
  });
}
function _delete(id) {
  return service.fetch.delete(`/api/beneficiary/_delete`, JSON.stringify(id));
}
